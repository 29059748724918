import {Dispatch, FunctionComponent, SetStateAction} from 'react';
import styled from 'styled-components';
import {ChooseStyleUrl} from '../../onboarding-v2/components/ChooseStyleUrl';
import {isTablet} from '../../../utils/responsive';
import {t} from '@lingui/macro';

interface Props {
  setNoContextStyleUrl: Dispatch<SetStateAction<boolean>>;
}

export const NoContextStylePopup: FunctionComponent<Props> = ({
  setNoContextStyleUrl,
}) => {
  return (
    <ParentContainer>
      <Container>
        <Wrapper>
          <Header>{t`Sorry but your profile is missing enough posts to define your style`}</Header>
          <Title>{t`Instead please choose the profile of a LinkedIn creator that you like.`}</Title>
          <ChooseStyleUrl
            onNextStep={() => setNoContextStyleUrl(false)}
            setStyleUrl={(styleUrl) => console.log('set styleUrl', styleUrl)}
          />
        </Wrapper>
      </Container>
    </ParentContainer>
  );
};

const ParentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000099;
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 60;
`;

const Container = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  padding: 2em 10em;
  border-radius: 2em;
  position: relative;
  scale: 0.9;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: calc(100vh - 1em);
  @media (max-width: ${isTablet}) {
    padding: 2em 2em;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  width: 70em;
  @media (max-width: ${isTablet}) {
    max-width: 100%;
  }
`;

const Header = styled.span`
  font-size: 3em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
  text-align: center;
`;

const Title = styled.h2`
  color: ${({theme}) => theme.colors.neutral.shade9};
  font-size: 1.4em;
  font-weight: 400;
  text-align: center;
`;
