import React, {FunctionComponent, useEffect, useState} from 'react';
import styled from 'styled-components';
import {t} from '@lingui/macro';
import {Button} from '../../../components/atoms/Button';
import {isTablet} from '../../../utils/responsive';
import {isValidLinkedInProfileUrl} from '../../../utils/isValidLinkedInProfileUrl';
import {TextInput} from '../../../components/atoms/TextInput';
import {LanguageSelect} from '../../../components/atoms/LanguageSelect';
import useDefaultLanguage from '../../../hooks/postLang/useDefaultLanguage';
import {Context} from './RedactPostContext';
import TrashIcon from '../../../components/atoms/Icons/TrashIcon';
import {useTheme} from '../../../components/theme/theme';

interface Props {
  isEdit: boolean;
  contextIdToEdit?: number;
  savedContexts?: Context[];
  setEditContext?: React.Dispatch<React.SetStateAction<boolean>>;
  setCreateContext?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditCreateContext: FunctionComponent<Props> = ({
  isEdit,
  contextIdToEdit,
  savedContexts,
  setEditContext,
  setCreateContext,
}) => {
  const [contextName, setContextName] = useState<string>('');
  const [contextProfileUrl, setContextProfileUrl] = useState<string>('');
  const [styleProfileUrl, setStyleProfileUrl] = useState<string>('');
  const [contextLang, setContextLang] = useDefaultLanguage();
  const theme = useTheme();

  const [errors, setErrors] = useState<{
    contextName?: string;
    contextProfileUrl?: string;
    styleProfileUrl?: string;
  }>({});

  useEffect(() => {
    if (isEdit && contextIdToEdit && savedContexts) {
      const contextToEdit = savedContexts.find(
        (context) => context.id === contextIdToEdit,
      );
      if (contextToEdit) {
        setContextName(contextToEdit.name);
        setContextProfileUrl(contextToEdit.contextProfileUrl);
        setStyleProfileUrl(contextToEdit.styleProfileUrl);
        setContextLang(contextToEdit.contextLang);
      }
    }
  }, [isEdit, contextIdToEdit, savedContexts, setContextLang]);

  const validateForm = (): boolean => {
    const newErrors: typeof errors = {};

    if (!contextName.trim()) {
      newErrors.contextName = t`Context name is required`;
    }
    if (!isValidLinkedInProfileUrl(contextProfileUrl)) {
      newErrors.contextProfileUrl = t`Please enter a valid LinkedIn profile URL`;
    }
    if (!isValidLinkedInProfileUrl(styleProfileUrl)) {
      newErrors.styleProfileUrl = t`Please enter a valid LinkedIn profile URL`;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClose = () => {
    if (isEdit) {
      setEditContext && setEditContext(false);
    }
    setCreateContext && setCreateContext(false);
  };

  const handleCreateContext = async () => {
    if (validateForm()) {
      console.log('Creating context here:', {
        contextName,
        contextProfileUrl,
        styleProfileUrl,
        lang: contextLang,
      });
      setCreateContext && setCreateContext(false);
    }
  };

  const handleEditContext = async () => {
    if (validateForm()) {
      console.log('Editing context here:', {
        contextName,
        contextProfileUrl,
        styleProfileUrl,
        lang: contextLang,
      });
      setEditContext && setEditContext(false);
    }
  };

  return (
    <Container>
      <Header>
        <Title>{isEdit ? t`Edit my context` : t`New context`}</Title>
        {isEdit && (
          <Delete>
            <TrashIcon
              height={19}
              width={19}
              color={theme.colors.primary2.shade3}
            />{' '}
            {t`Delete context`}
          </Delete>
        )}
      </Header>
      <FormContainer>
        <StyledTextInput
          label={t`Context name`}
          placeholderText={t`Context Name (example: Elon Musk)`}
          value={contextName}
          onChange={(event) => {
            setContextName(event.target.value);
            setErrors((prev) => ({...prev, contextName: undefined}));
          }}
          error={!!errors.contextName}
          helperText={errors.contextName}
        />
        <StyledTextInput
          label={t`Context profile URL`}
          placeholderText={t`LinkedIn context profile url (example: https://www.linkedin.com/in/elonmusk/)`}
          value={contextProfileUrl}
          onChange={(event) => {
            setContextProfileUrl(event.target.value);
            setErrors((prev) => ({...prev, contextProfileUrl: undefined}));
          }}
          error={!!errors.contextProfileUrl}
          helperText={errors.contextProfileUrl}
          disabled={isEdit}
        />
        <StyledTextInput
          label={t`Writing style profile URL`}
          placeholderText={t`LinkedIn profile style url (example: https://www.linkedin.com/in/elonmusk/)`}
          value={styleProfileUrl}
          onChange={(event) => {
            setStyleProfileUrl(event.target.value);
            setErrors((prev) => ({...prev, styleProfileUrl: undefined}));
          }}
          error={!!errors.styleProfileUrl}
          helperText={errors.styleProfileUrl}
        />
        <SelectContainer>
          <LanguageSelect
            postLang={contextLang}
            setPostLang={setContextLang}
            onChange={(selectedLang) => setContextLang(selectedLang.value)}
          />
        </SelectContainer>
      </FormContainer>
      <End>
        <Button
          label={t`Go back`}
          size="medium"
          variant="light"
          onClick={handleClose}
        />
        <StyledButton
          label={isEdit ? t`Save` : t`Create`}
          size="medium"
          variant="main"
          onClick={isEdit ? handleEditContext : handleCreateContext}
        />
      </End>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: min(1100px, 80%);
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 1.5em;
  font-weight: 700;
  margin: 0;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const End = styled.div`
  width: 100%;
  display: flex;
  gap: 1.25em;
  justify-content: space-between;
  @media (max-width: ${isTablet}) {
    flex-direction: column-reverse;
    gap: 1.5em;
  }
`;

const StyledTextInput = styled(TextInput)<{error?: boolean}>`
  width: 100%;
  margin-bottom: ${(props) => (props.error ? '16px' : '0')};
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const SelectContainer = styled.div`
  width: 15em;
`;

const StyledButton = styled(Button)`
  @media (min-width: ${isTablet}) {
    width: 15em;
  }
`;

const Delete = styled.p`
  margin: 0;
  color: ${({theme}) => theme.colors.primary2.shade3};
  font-size: 1em;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;
`;
