import React, {Dispatch, SetStateAction, useState} from 'react';
import styled from 'styled-components';
import {PostGeneratorIntro} from './PostGeneratorIntro';
import {ChooseUserContext} from '../../onboarding-v2/components/ChooseUserContext';
import {t} from '@lingui/macro';

interface Props {
  setNoContext: Dispatch<SetStateAction<boolean>>;
}

export const CreateContextPopup: React.FC<Props> = ({setNoContext}) => {
  const [isChooseContext, setIsChooseContext] = useState<boolean>(false);

  return (
    <ParentContainer>
      <Container>
        <ScrollableContent>
          <Wrapper>
            {isChooseContext ? (
              <>
                <TopWrapper>
                  <Header>{t`Create your first context`}</Header>
                  <Title>{t`In order to personalise your experience we need your LinkedIn profile url.`}</Title>
                  <Subtitle>{t`If you create posts for multiple clients don't worry, you will be able to create more contexts after.`}</Subtitle>
                </TopWrapper>
                <ChooseUserContext
                  onNextStep={() => setNoContext(false)}
                  setContext={(context) => console.log('set context', context)}
                />
              </>
            ) : (
              <PostGeneratorIntro
                onCreateContext={() => setIsChooseContext(true)}
              />
            )}
          </Wrapper>
        </ScrollableContent>
      </Container>
    </ParentContainer>
  );
};

const ParentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 60;
`;

const Container = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 2em;
  position: relative;
  width: 100%;
  max-width: 80em;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ScrollableContent = styled.div`
  overflow-y: auto;
  max-height: calc(90vh - 4em);
  padding: 2em;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  width: 100%;
`;

const Header = styled.h2`
  font-size: 2.5em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
  text-align: center;
  margin-bottom: 0.5em;
`;

const Title = styled.p`
  color: ${({theme}) => theme.colors.neutral.shade9};
  font-size: 1.2em;
  font-weight: 400;
  text-align: center;
  margin: 0;
`;

const Subtitle = styled.p`
  color: ${({theme}) => theme.colors.neutral.shade9};
  font-size: 1em;
  font-weight: 300;
  text-align: center;
  margin: 0;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: 100%;
`;
