import {
  Fragment,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {t} from '@lingui/macro';
import {RedactedPost} from '../../../hooks/redactPostNew/useRedactPostMutation';
import {GeneratedPost} from '../../../components/molecules/GeneratedPost';
import {Message} from '../../../components/atoms/Message';
import {UserContext} from '../../../common/UserContext/user.context';
import {useTracking} from '../../../hooks/useTracking';
import {ChatInputBar} from '../../../components/molecules/ChatInputBar';
import {isTablet} from '../../../utils/responsive';
import {
  RegeneratedPost,
  RegeneratePostResponse,
  useRegeneratePostMutation,
} from '../../../hooks/regeneratePost/useRegeneratePostMutation';

interface Props {
  posts: RedactedPost[];
  isOnboarding: boolean;
  isChatWithRedactAI?: boolean;
}

interface ChatItem {
  render: () => JSX.Element;
  getPostIds: () => string[];
}

class ChatRegeneratedPostItem implements ChatItem {
  constructor(
    private readonly posts: RegeneratedPost[],
    private readonly name: string,
    private readonly isLoading: boolean,
    private readonly isOnboarding: boolean,
  ) {}

  render() {
    if (this.isLoading) {
      return (
        <>
          {[...Array(3)].map((_, index) => (
            <GeneratedPost
              key={index}
              name={this.name}
              id={index.toString()}
              text=""
              height={!this.isOnboarding ? '38em' : '34em'}
              isLoading={true}
            />
          ))}
        </>
      );
    }
    return (
      <>
        {this.posts.map((post, index) => (
          <GeneratedPost
            key={index}
            name={this.name}
            id={post.id}
            text={post.text}
            height={!this.isOnboarding ? '38em' : '34em'}
            isLoading={false}
          />
        ))}
      </>
    );
  }

  getPostIds() {
    return this.posts.map((post) => post.id);
  }
}

class ChatMessageItem implements ChatItem {
  constructor(private readonly message: string) {}

  render() {
    return <Message message={this.message} />;
  }

  getPostIds() {
    return [];
  }
}

export const ChatWithRedactAIChatArea: FunctionComponent<Props> = ({
  posts,
  isOnboarding,
  isChatWithRedactAI = false,
}) => {
  const {me} = useContext(UserContext);
  const {trackEvent} = useTracking();
  const [message, setMessage] = useState<string>('');
  const regeneratePost = useRegeneratePostMutation();
  const [chatItems, setChatItems] = useState<ChatItem[]>([]);
  const [lastPostsIds, setLastPostsIds] = useState<string[]>(
    posts.map((post) => post.id),
  );
  const chatAreaRef = useRef<HTMLDivElement>(null);

  const quickActions = [
    t`Remove emojis`,
    t`Make it longer`,
    t`Make it shorter`,
    t`Be more formal`,
    t`Add "X"`,
    t`Remove "Y"`,
    t`Change "X" by "Y"`,
  ];

  const scrollToBottom = () => {
    if (chatAreaRef.current) {
      chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight - 500;
    }
  };

  useEffect(() => {
    setLastPostsIds(posts.map((post) => post.id));
  }, [posts]);

  const onRegeneratePostSubmit = useCallback(async () => {
    trackEvent('ChatWithRedactAI - Click - Regenerate posts', {
      text: message,
      isDefaultText: message ? false : true,
    });
    setChatItems((prevData) => [
      ...prevData,
      new ChatMessageItem(!message ? t`Regenerate my posts` : message),
      new ChatRegeneratedPostItem(
        [],
        `${me?.firstName} ${me?.lastName}`,
        true,
        isOnboarding,
      ),
    ]);
    regeneratePost.mutate({
      lastPostsIds: lastPostsIds,
      message,
    });
    setMessage('');
  }, [
    regeneratePost,
    lastPostsIds,
    message,
    me?.firstName,
    me?.lastName,
    isOnboarding,
    trackEvent,
  ]);

  useEffect(() => {
    const getData = async () => {
      if (
        regeneratePost.isError ||
        !regeneratePost.isSuccess ||
        !regeneratePost.data
      ) {
        return;
      }
      const response = regeneratePost.data as Response;
      if (!response.ok) {
        return;
      }
      const data = (await response.json()) as RegeneratePostResponse;
      if (!data.posts.length) {
        return;
      }
      setLastPostsIds(data.posts.map((post) => post.id));
      setChatItems((prevData) => {
        const newData = [...prevData];
        newData[newData.length - 1] = new ChatRegeneratedPostItem(
          data.posts,
          `${me?.firstName} ${me?.lastName}`,
          false,
          isOnboarding,
        );
        return newData;
      });
    };
    getData();
  }, [
    me?.firstName,
    me?.lastName,
    regeneratePost.isError,
    regeneratePost.isSuccess,
    regeneratePost.data,
    isOnboarding,
  ]);

  useEffect(() => {
    scrollToBottom();
  }, [chatItems]);

  return (
    <>
      <ChatArea $isOnboarding={isOnboarding} ref={chatAreaRef}>
        <PostsContainer $isOnboarding={isOnboarding}>
          {posts &&
            posts.map((post, index) => (
              <GeneratedPost
                key={`generated-${index}`}
                name={`${me?.firstName} ${me?.lastName}`}
                text={post.text}
                id={post.id}
                height={!isOnboarding ? '38em' : '34em'}
                isLoading={false}
                isOnboarding={isOnboarding}
              />
            ))}
        </PostsContainer>
        {chatItems.map((item, index) => (
          <PostsContainer $isOnboarding={isOnboarding} key={index}>
            <Fragment>{item.render()}</Fragment>
          </PostsContainer>
        ))}
      </ChatArea>
      {isChatWithRedactAI && (
        <Footer $isOnboarding={isOnboarding}>
          <ChatInputBar
            fullWidth
            label={t`Tell RedactAI what to change`}
            placeholder={t`Not satisfied with the result ? Tell RedactAI what to change you can leave empty to regenerate new variations`}
            {...{
              quickActions,
              message,
              setMessage,
              onRegeneratePostSubmit,
              loading: regeneratePost.isPending,
            }}
          />
        </Footer>
      )}
    </>
  );
};

const ChatArea = styled.div<{$isOnboarding: boolean}>`
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  row-gap: 1.5em;
  align-items: end;
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 100%;
  ${({$isOnboarding, theme}) => {
    if ($isOnboarding) {
      return `
        background-color: ${theme.colors.primary1.shade5};
        `;
    } else {
      return `
        `;
    }
  }}
`;

const PostsContainer = styled.div<{$isOnboarding: boolean}>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  column-gap: 1.5em;

  @media (max-width: ${isTablet}) {
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 1.5em;
    align-items: flex-end;
  }
`;

const Footer = styled.div<{$isOnboarding: boolean}>`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  ${({$isOnboarding}) => {
    if ($isOnboarding) {
      return `
            padding: 1.5em;
        `;
    } else {
      return `
            width: 100%;
            padding: 2em;
            margin-left: -0.5em;
        `;
    }
  }}
`;
