import {useState, useEffect, FunctionComponent, ChangeEvent} from 'react';
import styled from 'styled-components';
import {TextInput} from '../../../components/atoms/TextInput';
import {t} from '@lingui/macro';
import HelpRoundedIcon from '../../../components/atoms/Icons/HelpRoundedIcon';
import {isTablet} from '../../../utils/responsive';
import {MyOwnIdeasToolTipFirstStep} from './MyOwnIdeasToolTipFirstStep';
import {MyOwnIdeasToolTipFinalStep} from './MyOwnIdeasToolTipFinalStep';
import {SavedPostLanguage} from '../../../hooks/savedPost/savedPost.types';
import {LanguageSelect} from '../../../components/atoms/LanguageSelect';
import {Button} from '../../../components/atoms/Button';
import useDefaultLanguage from '../../../hooks/postLang/useDefaultLanguage';
import {usePostSubjectSuggestions} from '../../../hooks/redactPostNew/usePostSubjectSuggestions';
import {Context} from '../../redactpost-v2/components/RedactPostContext';

interface ContentData {
  postSubject: string;
  postIdeas: string;
  language: SavedPostLanguage;
}

interface Props {
  onNextStep: () => void;
  setContent: (content: ContentData) => void;
  context: Context;
}

export const FillInContent: FunctionComponent<Props> = ({
  onNextStep,
  setContent,
  context,
}) => {
  const [firstStep, setfirstStep] = useState<boolean>(false);
  const [finalStep, setfinalStep] = useState<boolean>(false);
  const [defaultLanguage] = useDefaultLanguage();
  const [postSubject, setPostSubject] = useState('');
  const [postIdeas, setPostIdeas] = useState('');
  const [language, setLanguage] = useState(defaultLanguage);
  const [postSubjectError, setPostSubjectError] = useState('');

  const {
    mutate: fetchSuggestions,
    data: suggestions = [],
    isPending: isLoading,
  } = usePostSubjectSuggestions();

  useEffect(() => {
    if (context) {
      fetchSuggestions(context);
    }
  }, [context, fetchSuggestions]);

  const handleNextStep = () => {
    if (!postSubject.trim()) {
      setPostSubjectError('Post subject is required');
      return;
    }
    setContent({
      postSubject,
      postIdeas,
      language,
    });
    onNextStep();
  };

  const handlePostSubjectChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPostSubject(event.target.value);
    setPostSubjectError('');
  };

  const handlePostIdeasChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setPostIdeas(event.target.value);
  };

  return (
    <Container>
      <ContentContainer>
        <InputContainer>
          <StyledTextInput
            label={t`Subject`}
            placeholderText={t`Subject of your post. Example: "How to increase your sales by 30% in 3 months"`}
            value={postSubject}
            onChange={handlePostSubjectChange}
            error={!!postSubjectError}
            helperText={postSubjectError}
          />
          <StyledHelpRoundedIcon
            $centered
            onClick={() => {
              setfirstStep(!firstStep);
              setfinalStep(false);
            }}>
            <StyHelpRoundedIcon />
          </StyledHelpRoundedIcon>
          {firstStep && (
            <MyOwnIdeasToolTipFirstStep {...{setfirstStep, setfinalStep}} />
          )}
        </InputContainer>
        <SuggestionsContainer
          $postSubjectError={postSubjectError}
          $postSubject={postSubject}>
          <Quest>{t`Do you want suggestions on ideas?`}</Quest>
          <Suggestions>
            {isLoading ? (
              <Suggestion>Loading suggestions...</Suggestion>
            ) : (
              suggestions.map((suggestion: string, index: number) => (
                <Suggestion
                  key={index}
                  onClick={() => {
                    setPostSubject(suggestion);
                    setPostSubjectError('');
                  }}>
                  {suggestion}
                </Suggestion>
              ))
            )}
          </Suggestions>
        </SuggestionsContainer>
        <InputContainer>
          {finalStep && (
            <MyOwnIdeasToolTipFinalStep {...{setfirstStep, setfinalStep}} />
          )}
          <StyledTextArea
            label={t`Key Ideas`}
            placeholderText={
              t`Key ideas.` +
              '\n\n' +
              t`Example:` +
              '\n\n' +
              t`- Proximity with clients` +
              '\n' +
              t`- Cheap` +
              '\n' +
              t`- ...`
            }
            value={postIdeas}
            onChange={handlePostIdeasChange}
            multiline
            minRows={8}
            maxRows={16}
          />
          <StyledHelpRoundedIcon
            $centered={false}
            onClick={() => {
              setfinalStep(!finalStep);
              setfirstStep(false);
            }}>
            <StyHelpRoundedIcon />
          </StyledHelpRoundedIcon>
        </InputContainer>
        <SelectContainer>
          <LanguageSelect
            postLang={language}
            setPostLang={setLanguage}
            onChange={(selectedLang) => {
              setLanguage(selectedLang.value);
            }}
          />
        </SelectContainer>
      </ContentContainer>
      <ButtonContainer>
        <Button
          label={t`Generate my post`}
          size="big"
          variant="main"
          onClick={handleNextStep}
          fullWidth
        />
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: 100%;
`;

const ContentContainer = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  width: 100%;
  box-sizing: border-box;
`;

const InputContainer = styled.div`
  position: relative;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

const StyledTextArea = styled(TextInput)`
  width: 100%;
`;

const StyledHelpRoundedIcon = styled.div<{$centered: boolean}>`
  position: absolute;
  right: 1em;
  top: ${({$centered}) => ($centered ? '50%' : '2em')};
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  background-color: white;
  height: 1.5em;
  padding-left: 6px;
`;

const StyHelpRoundedIcon = styled(HelpRoundedIcon)`
  cursor: pointer;
  @media (max-width: ${isTablet}) {
    height: 1.25em;
    width: 1.25em;
  }
`;

const SelectContainer = styled.div`
  width: 15em;
`;

const Quest = styled.span`
  color: ${({theme}) => theme.colors.primary4.shade10};
  font-size: 0.75em;
  letter-spacing: 0.0125em;
`;

const Suggestions = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0.5em;
  flex-direction: column;
`;

const SuggestionsContainer = styled.div<{
  $postSubjectError: string;
  $postSubject: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  margin-top: ${({$postSubjectError, $postSubject}) =>
    $postSubjectError && !$postSubject && '0.7em'};
`;

const Suggestion = styled.div`
  display: inline-flex;
  background-color: ${({theme}) => theme.colors.neutral.shade3};
  border-radius: 3em;
  padding: 0.5em 0.75em;
  color: ${({theme}) => theme.colors.neutral.shade9};
  font-size: 0.75em;
  cursor: pointer;
  align-self: flex-start;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  padding: 0 15em;
  @media (max-width: ${isTablet}) {
    padding: 0 5em;
  }
`;
