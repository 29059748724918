import {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import {t} from '@lingui/macro';
import {TextInput} from '../../../components/atoms/TextInput';
import {isValidLinkedInProfileUrl} from '../../../utils/isValidLinkedInProfileUrl';
import {ProfileUrlStyleToolTip} from './ProfileUrlStyleToolTip';
import HelpRoundedIcon from '../../../components/atoms/Icons/HelpRoundedIcon';
import {Button} from '../../../components/atoms/Button';
import {useFetchContext} from '../../../hooks/redactPostNew/useFetchContext';
import {isTablet} from '../../../utils/responsive';
import {Context} from '../../redactpost-v2/components/RedactPostContext';

interface Props {
  onNextStep: () => void;
  setContext: (context: Context) => void;
}

export const ChooseUserContext: FunctionComponent<Props> = ({
  onNextStep,
  setContext,
}) => {
  const [profileUrl, setProfileUrl] = useState<string>('');
  const [profileUrlError, setProfileUrlError] = useState<string>('');
  const [isToolTipVisible, setIsToolTipVisible] = useState<boolean>(false);

  const {mutate: fetchUserContext, isPending: isLoading} = useFetchContext();

  const handleNextStep = async () => {
    if (!isValidLinkedInProfileUrl(profileUrl)) {
      setProfileUrlError(t`Please enter a valid LinkedIn profile URL`);
      return;
    }

    fetchUserContext(profileUrl, {
      onSuccess: (context) => {
        setContext(context);
        onNextStep();
      },
      onError: () => {
        setProfileUrlError(t`Failed to fetch user context. Please try again.`);
      },
    });
  };

  return (
    <ContentContainer>
      <InputContainer>
        <StyledTextInput
          label={t`LinkedIn Profile URL`}
          placeholderText={'https://www.linkedin.com/in/yourprofile'}
          value={profileUrl}
          onChange={(event) => {
            setProfileUrl(event.target.value);
            setProfileUrlError('');
          }}
          error={!!profileUrlError}
          helperText={profileUrlError}
        />
        <StyledHelpIconContainer
          onClick={() => setIsToolTipVisible(!isToolTipVisible)}>
          <StyledHelpRoundedIcon />
        </StyledHelpIconContainer>
        {isToolTipVisible && (
          <ProfileUrlStyleToolTip setIsToolTip={setIsToolTipVisible} />
        )}
      </InputContainer>
      <ButtonContainer>
        <Button
          label={isLoading ? t`Getting your context...` : t`Let's go!`}
          size="big"
          variant="main"
          onClick={handleNextStep}
          fullWidth
          disabled={isLoading}
        />
      </ButtonContainer>
      <FooterTitle>
        {t`*RedactAI will retrieve your public profile information to know you better.`}
      </FooterTitle>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: 100%;
`;

const FooterTitle = styled.h2`
  color: ${({theme}) => theme.colors.neutral.shade9};
  font-size: 1.1em;
  font-weight: 400;
  margin-bottom: 1em;
  text-align: center;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 2em;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4em;
  padding: 0 15em;
  @media (max-width: ${isTablet}) {
    padding: 0 5em;
  }
`;

const StyledHelpIconContainer = styled.div`
  position: absolute;
  right: 1em;
  cursor: pointer;
`;

const StyledHelpRoundedIcon = styled(HelpRoundedIcon)`
  color: ${({theme}) => theme.colors.neutral.shade7};
`;
