import {useMutation, useQueryClient} from '@tanstack/react-query';
import {t} from '@lingui/macro';
import {PostSubjectSuggestionsMock} from './postSubjectSuggestions.mock';
import {Context} from '../../pages/redactpost-v2/components/RedactPostContext';

export const usePostSubjectSuggestions = () => {
  const queryClient = useQueryClient();

  const findIdeas = useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['subscription'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['savedIdeas'],
      });
    },
    mutationFn: async (context: Context) => {
      return PostSubjectSuggestionsMock();

      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/postSubjectSuggestions`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({context}),
        },
      );

      if (response.ok) {
        return response.json();
      }

      throw new Error(t`Failed to fetch suggestions. Please try again.`);
    },
  });

  return findIdeas;
};
