import {RedactPostResponse} from './useRedactPostMutation';

export const redactPostMock = () => {
  return new Promise<Response>((resolve) => {
    setTimeout(() => {
      const mock: RedactPostResponse = {
        posts: [
          {
            id: '1',
            text: `How I mastered underwater basket weaving overnight.

Decades of curiosity, unraveled in a single evening.

I've always been fascinated by the art of underwater basket weaving.
No book, documentary, or workshop seemed to click.
I was on the lookout for a method that adapts to my pace and style.

To master this with the help of an AI, here's what I did:

Set the scene
→ Begin by immersing yourself in water to understand the environment.
→ e.g., a bathtub filled with lukewarm water

Gather your materials
→ The right tools make all the difference! AI can suggest materials based on your preferences.
→ e.g., flexible reeds or synthetic fibers that are water-friendly

Seek guidance
→ It's easy to get tangled. AI can offer step-by-step guidance tailored to your progress.
→ e.g., Techniques for weaving under water without the reeds floating away

Iterate and improve
→ With each basket, refine your technique based on AI feedback.
→ e.g., Adjust the tightness of your weave or experiment with different patterns

P.S. I now teach online courses on unconventional crafts. Visit my profile to see if we can dive into creativity together.`,
            isFavourite: false,
          },
          {id: '2', text: 'MockPost', isFavourite: false},
          {id: '3', text: 'MockPost', isFavourite: false},
        ],
      };
      const blob = new Blob([JSON.stringify(mock, null, 2)], {
        type: 'application/json',
      });
      const options = {status: 200};
      resolve(new Response(blob, options));
    }, 25000);
  });
};
