import {Dispatch, FunctionComponent, SetStateAction} from 'react';
import {t} from '@lingui/macro';
import styled from 'styled-components';
import {RedactPostContextSelect} from './RedactPostContextSelect';
import {SavedPostLanguage} from '../../../hooks/savedPost/savedPost.types';

export interface Context {
  id: number;
  name: string;
  avatarUrl: string;
  headline: string;
  summary: string;
  jobs: string[];
  contextProfileUrl: string;
  styleProfileUrl: string;
  contextLang: SavedPostLanguage;
}

interface Props {
  postContext: Context | undefined;
  setPostContext: Dispatch<SetStateAction<Context | undefined>>;
  savedContexts: Context[];
  setEditContext: Dispatch<SetStateAction<boolean>>;
  setCreateContext: Dispatch<SetStateAction<boolean>>;
  setContextIdToEdit: Dispatch<SetStateAction<number | undefined>>;
}

export const RedactPostContext: FunctionComponent<Props> = ({
  postContext,
  setPostContext,
  savedContexts,
  setEditContext,
  setCreateContext,
  setContextIdToEdit,
}) => {
  return (
    <ContentContainer>
      <P>{t`Context`}</P>
      <RedactPostContextSelect
        selectedContext={postContext}
        setSelectedContext={setPostContext}
        contextOptions={savedContexts}
        setEditContext={setEditContext}
        setCreateContext={setCreateContext}
        setContextIdToEdit={setContextIdToEdit}
      />
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin-top: -0.5em;
  width: 100%;
  box-sizing: border-box;
  border: 3px solid ${({theme}) => theme.colors.neutral.shade4};
`;

const P = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;
