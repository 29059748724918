import {useQuery} from '@tanstack/react-query';
import {savedContextsMock} from './savedContexts.mock';
import {fetchApi} from '../../utils/fetchApi';
import {useContext, useMemo} from 'react';
import {UserContext} from '../../common/UserContext/user.context';
import {Context} from '../../pages/redactpost-v2/components/RedactPostContext';

export const useFetchSavedContexts = () => {
  const {me} = useContext(UserContext);
  const {data, isLoading, refetch} = useQuery({
    queryKey: ['savedContexts'],
    queryFn: async () => {
      const isDevelopment = import.meta.env.VITE_NODE_ENV === 'development';
      const withAI = !isDevelopment || import.meta.env.VITE_WITH_AI === 'true';
      return withAI
        ? await fetchApi(`/savedContexts`)
        : await savedContextsMock();
    },
    enabled: !!me,
  });

  const savedContexts: Context[] = useMemo(
    () =>
      !data
        ? []
        : data.map((element: Context) => ({
            ...element,
          })),
    [data],
  );

  return {
    savedContexts,
    loading: isLoading,
    refetch,
  };
};
