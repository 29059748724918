import {Context} from '../../pages/redactpost-v2/components/RedactPostContext';
import {SavedPostLanguage} from '../savedPost/savedPost.types';

export const fetchContextMock = (profileUrl: string): Promise<Context> => {
  return new Promise((resolve) => {
    const mockContext: Context = {
      id: 1,
      name: `Mock User`,
      avatarUrl: `https://mock.com/avatar/${profileUrl}`,
      headline: `Mock headline for ${profileUrl}`,
      summary: `This is a mock summary for ${profileUrl}`,
      jobs: ['Mock Job 1', 'Mock Job 2'],
      contextProfileUrl: `${profileUrl}`,
      styleProfileUrl: ``,
      contextLang: SavedPostLanguage.ENGLISH,
    };

    setTimeout(() => resolve(mockContext), 1000);
  });
};
