import {useMutation, useQueryClient} from '@tanstack/react-query';
import {redactPostMock} from './redactPost.mock';

export interface RedactedPost {
  id: string;
  text: string;
  isFavourite: boolean;
}

export interface RedactPostResponse {
  posts: RedactedPost[];
}

export class RedactPostError extends Error {
  constructor(
    message: string,
    public readonly level: 'error' | 'warning' | 'info',
  ) {
    super(message);
    this.name = 'RedactPostError';
  }
}

export const useRedactPostMutation = () => {
  const queryClient = useQueryClient();

  const redactPost = useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({queryKey: ['subscription']});
      await queryClient.invalidateQueries({queryKey: ['savedPosts']});
    },
    mutationFn: async () => {
      return redactPostMock();
    },
  });

  return redactPost;
};
