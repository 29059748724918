import {useCallback, useContext, useEffect, useState} from 'react';
import {SavedPostLanguage} from '../savedPost/savedPost.types';
import {useTracking} from '../useTracking';
import {UserContext} from '../../common/UserContext/user.context';
import {useSnackbar} from 'notistack';
import {
  RedactedPost,
  RedactPostError,
  RedactPostResponse,
  useRedactPostMutation,
} from './useRedactPostMutation';
import {Context} from '../../pages/redactpost-v2/components/RedactPostContext';

export const useRedactPost = (
  postSubject: string,
  postIdeas: string,
  context: Context,
  postLang: SavedPostLanguage,
) => {
  const [redactedPosts, setRedactedPosts] = useState<RedactedPost[]>([]);
  const [showPricingPopup, setShowPricingPopup] = useState(false);
  const redactPost = useRedactPostMutation();
  const {me} = useContext(UserContext);
  const {trackEvent} = useTracking();
  const {enqueueSnackbar} = useSnackbar();

  const onGeneratePostSubmit = useCallback(async () => {
    const postToRedact = {
      subject: postSubject,
      Ideas: postIdeas,
      context: context,
      language: postLang,
    };

    localStorage.setItem(
      'redactPostFormParameters',
      JSON.stringify(postToRedact),
    );
    if (me && !me.hasFinishedOnboarding) {
      trackEvent('Onboarding - Click - Generate post');
    }
    redactPost.mutate();
  }, [postIdeas, postLang, postSubject, context, redactPost, me, trackEvent]);

  useEffect(() => {
    if (!redactPost.isError) {
      return;
    }
    const error = redactPost.error as RedactPostError;
    enqueueSnackbar(error.message, {
      variant: error.level,
      autoHideDuration: 15000,
    });
  }, [enqueueSnackbar, redactPost.error, redactPost.isError]);

  useEffect(() => {
    const fetchRedactedPosts = async () => {
      if (redactPost.isError || !redactPost.isSuccess || !redactPost.data) {
        return;
      }
      const response = redactPost.data as Response;
      if (!response.ok) {
        return;
      }
      const data = (await response.json()) as RedactPostResponse;
      setRedactedPosts(data.posts);
    };

    fetchRedactedPosts();
  }, [redactPost.data, redactPost.isError, redactPost.isSuccess]);

  return {
    onGeneratePostSubmit,
    redactedPosts,
    isLoading: redactPost.isPending,
    showPricingPopup,
    setShowPricingPopup,
    error: redactPost.error || redactPost.isError,
  };
};
