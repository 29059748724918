import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import styled, {keyframes} from 'styled-components';
import {useTheme} from '../../../components/theme/theme';
import {useClickOutside} from '../../../hooks/useClickOutside';
import {Context} from './RedactPostContext';
import {isTablet} from '../../../utils/responsive';
import ChevronBottom from '../../../components/atoms/Icons/ChevronBottom';
import {t} from '@lingui/macro';
import {Button} from '../../../components/atoms/Button';
import EditIcon from '../../../components/atoms/Icons/EditIcon';

interface Props {
  selectedContext: Context | undefined;
  setSelectedContext: Dispatch<SetStateAction<Context | undefined>>;
  contextOptions: Context[];
  setEditContext: Dispatch<SetStateAction<boolean>>;
  setCreateContext: Dispatch<SetStateAction<boolean>>;
  setContextIdToEdit: Dispatch<SetStateAction<number | undefined>>;
}

export const RedactPostContextSelect: FunctionComponent<Props> = ({
  selectedContext,
  setSelectedContext,
  contextOptions,
  setEditContext,
  setCreateContext,
  setContextIdToEdit,
}) => {
  const theme = useTheme();
  const [isActive, setIsActive] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside(containerRef, () => setIsActive(false));

  return (
    <Container
      ref={containerRef}
      $active={isActive}
      onClick={() => setIsActive(!isActive)}>
      <AvatarNameContainer>
        <Avatar src={selectedContext?.avatarUrl} alt={selectedContext?.name} />
        <ContextName>{selectedContext?.name}</ContextName>
      </AvatarNameContainer>
      <StyledChevronBottom
        $active={isActive}
        color={theme.colors.neutral.shade11}
      />
      {isActive && (
        <Dropdown>
          {contextOptions.map((context) => (
            <ContextItemContainer
              key={context.id}
              $selected={selectedContext?.id === context.id}
              onClick={() => {
                setSelectedContext(context);
                setIsActive(false);
              }}>
              <AvatarNameContainer>
                <Avatar src={context.avatarUrl} alt={context.name} />
                <ContextName>{context.name}</ContextName>
              </AvatarNameContainer>
              <EditButton
                onClick={(event) => {
                  event.stopPropagation();
                  setContextIdToEdit(context.id);
                  setEditContext(true);
                  setIsActive(false);
                }}>
                <StyledEditIcon width={20} height={20} />
                {t`Edit`}
              </EditButton>
            </ContextItemContainer>
          ))}
          <StyledMainButton
            label={t`New context +`}
            size="small"
            variant="main"
            onClick={() => setCreateContext(true)}
          />
        </Dropdown>
      )}
    </Container>
  );
};

const Container = styled.div<{$active: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.625em;
  justify-content: space-between;
  padding: 0.59em 1em;
  border-radius: 0.75em;
  border: 1px solid
    ${({theme, $active}) =>
      $active ? theme.colors.primary1.shade2 : theme.colors.neutral.shade5};
  box-shadow: ${({$active, theme}) =>
    $active ? `0px 0px 0px 4px ${theme.colors.primary1.shade1}` : 'none'};
  flex: 1;
  cursor: pointer;
  @media (max-width: ${isTablet}) {
    padding: 0.3em 1em;
  }
`;

const AvatarNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
`;

const Avatar = styled.img`
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  object-fit: cover;
`;

const ContextName = styled.span`
  font-weight: 600;
  margin-left: 0.75em;
`;

const StyledChevronBottom = styled(ChevronBottom)<{$active: boolean}>`
  transition: transform 0.3s ease;
  transform: ${({$active}) => ($active ? 'rotate(180deg)' : 'rotate(0deg)')};
  @media (max-width: ${isTablet}) {
    height: 1.25em;
    width: 1.25em;
  }
`;

const fadeIn = keyframes`
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `;

const Dropdown = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 4em;
  padding: 0.8em;
  border-radius: 0.75em;
  display: flex;
  flex-direction: column;
  gap: 0.625em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border: 1px solid ${({theme}) => theme.colors.neutral.shade5};
  z-index: 20;
  animation: ${fadeIn} 0.3s ease forwards;
  @media (max-width: ${isTablet}) {
    top: 3.8em;
  }
`;

const ContextItemContainer = styled.button<{$selected: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.625em;
  border-radius: 0.75em;
  padding: 0.5em;
  font-size: 0.875em;
  letter-spacing: 0.0125em;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade11};
  background-color: ${({theme, $selected}) =>
    $selected ? theme.colors.primary1.shade5 : 'transparent'};
  border: none;
  text-align: start;
  cursor: pointer;

  ${({theme, $selected}) =>
    $selected &&
    `
        border: 2px solid ${theme.colors.primary1.shade2};
      `}
`;

const EditButton = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: ${({theme}) => theme.colors.primary4.shade1};
  font-size: 1em;
  font-weight: 600;
`;

const StyledEditIcon = styled(EditIcon)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;

const StyledMainButton = styled(Button)`
  align-self: flex-end;
`;
