import {FunctionComponent} from 'react';
import styled from 'styled-components';
import {t} from '@lingui/macro';
import {Button} from '../../../components/atoms/Button';
import {isTablet} from '../../../utils/responsive';

interface Props {
  onCreateContext: () => void;
}

export const PostGeneratorIntro: FunctionComponent<Props> = ({
  onCreateContext,
}) => {
  return (
    <Container>
      <Title>
        {t`Introducing the new`} <Highlight>{t`Post Generator`}</Highlight>
      </Title>
      <Subtitle>{t`We worked hard to create a new post generator:`}</Subtitle>

      <FeatureSection>
        <div>
          <FeatureTitle>{t`1. RedactAI now loads all the data of your LinkedIn profile to give context to your posts.`}</FeatureTitle>
          <FeatureText>{t`Everything that you posted on LinkedIn will be used to enhance your content.`}</FeatureText>
          <FeatureHighlight>{t`This means that the AI knows you and will make references to your business, stories that you shared, product that you sell, ...`}</FeatureHighlight>
          <FeatureText>{t`This also means that the more that you post (or have posted), the better the AI will be.`}</FeatureText>
          <FeatureText>
            {t`This is called the`} <span>{t`context.`}</span>
          </FeatureText>
        </div>
        <StyleImage src={'https://i.ibb.co/F6Vtn54/Rectangle-66-1.png'} />
      </FeatureSection>

      <FeatureSection>
        <StyleImage src={'https://i.ibb.co/0yRN1G1/Frame-1171277214.png'} />
        <div>
          <FeatureTitle>{t`2. The post style now matches 99% of your writing style`}</FeatureTitle>
          <FeatureText>{t`We have trained the AI to better match your writing style from your previous posts.`}</FeatureText>
          <FeatureHighlight>{t`The AI now better reproduces your tone and post structure.`}</FeatureHighlight>
        </div>
      </FeatureSection>

      <FeatureSection>
        <div>
          <FeatureTitle>{t`3. We removed the default post tone`}</FeatureTitle>
          <FeatureText>{t`Some of you may still use the default post tone.`}</FeatureText>
          <FeatureHighlight>{t`We encourage you to use the profile url of another creator to imitate his writing style.`}</FeatureHighlight>
        </div>
        <CrossedOutImage src={'https://i.ibb.co/kymKTJC/Rectangle-66-3.png'} />
      </FeatureSection>

      <StyledButton
        label={t`Let's create my context`}
        size="medium"
        variant="main"
        onClick={onCreateContext}
        fullWidth
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  padding: 1em 2em;
`;

const Title = styled.p`
  font-size: 3em;
  font-weight: 700;
  text-align: center;
  margin: 0;
`;

const Highlight = styled.span`
  color: ${({theme}) => theme.colors.primary1.shade2};
`;

const Subtitle = styled.p`
  color: ${({theme}) => theme.colors.neutral.shade11};
  font-size: 1.2em;
  font-weight: 700;
  text-align: center;
  margin: 0;
`;

const FeatureSection = styled.div`
  background-color: ${({theme}) => theme.colors.primary1.shade1};
  padding: 2em;
  border-radius: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
  width: 100%;
  line-height: 1.5em;

  @media (max-width: ${isTablet}) {
    flex-direction: column;
  }
`;

const FeatureTitle = styled.h2`
  font-size: 1.2em;
  font-weight: 700;
`;

const FeatureText = styled.p`
  font-size: 1em;
  font-weight: 500;

  span {
    font-weight: 700;
  }
`;

const FeatureHighlight = styled.p`
  font-size: 1em;
  font-weight: 700;
`;

const StyleImage = styled.img`
  width: 100%;
  max-width: 30em;
  height: auto;
`;

const CrossedOutImage = styled.img`
  width: 100%;
  max-width: 30em;
  height: auto;
`;

const StyledButton = styled(Button)`
  width: 25em;
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;
