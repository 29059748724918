import {useState, useEffect} from 'react';
import {RedactedPost} from './useRedactPostMutation';

export const useLoadingProgress = (
  isLoading: boolean,
  posts?: RedactedPost[],
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const timeout = (45 + 60 + 60) * 10;

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        let increment = 10;
        if (prevProgress < 30) {
          increment = 1;
        }
        if (prevProgress >= 30 && prevProgress < 60) {
          increment = 10;
        }
        if (prevProgress >= 60 && prevProgress < 80) {
          increment = 20;
        }
        const newProgress = prevProgress + increment;
        if (newProgress >= 99) {
          clearInterval(interval);
          return 99;
        }
        return newProgress;
      });
    }, timeout);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!isLoading && posts && posts.length > 0) {
      setLoading(false);
    }
  }, [isLoading, posts]);

  return {loading, progress};
};
