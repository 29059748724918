import {useMutation, useQueryClient} from '@tanstack/react-query';
import {t} from '@lingui/macro';
import {fetchContextMock} from './fetchContext.mock';

export const useFetchContext = () => {
  const queryClient = useQueryClient();

  const fetchContext = useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['userContext'],
      });
    },
    mutationFn: async (profileUrl: string) => {
      return fetchContextMock(profileUrl);

      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/fetchUserContext`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({profileUrl}),
        },
      );

      if (response.ok) {
        const data = await response.json();
        return data.context;
      }

      throw new Error(t`Failed to fetch user context. Please try again.`);
    },
  });

  return fetchContext;
};
